export class Constants {


    static locationId = ""
    static customerId = "";
    static strPalletId = "";
    static dcId = "";
    static DC_MODE = "edge";
    static DC_MODE_EDGE = "edge"
    static DC_MODE_CLOUD = "cloud"
    static RPC_ADMIN = "RpcAdmin"
    static RPC_READ_ONLY = "RpcReadOnly"
    static PORTRAIT = "portrait";
    static LANDSCAPE = "landscape";
    static isAuditLoaded = false;

    static MISSING = "Missing";
    static EXTRA = "Extra";
    static CAN_NOT_CONFIRM = "Can’t confirm";
    static AUDITED = "Audited";
    static NO_ERROR = "No error";
    static UN_TRAINED = "UNTRAINED";
    static SCREEN_NAME = "";
    static PALLET_REVIEW = "PalletReview";
    static PALLET_ARCHIVE = "PalletArchive";

    static PANEL_SIDE1 = "side1";
    static PANEL_SIDE2 = "side2";
    static PANEL_SIDE3 = "side3";
    static PANEL_SIDE4 = "side4"
    static PANEL_DATE_FMT = "MM-DD-YYYY HH:mm:ss"

    static palletArchiveData = []

    static IS_IMAGE_CORRECTION_ENABLED = false;
    static IS_PALLET_REVIEW_REFRESH = false;
    static userId = ""

    static userRole = "";
    static userApps = "";

    static ROUTE_SELECTION_EVENT = "ROUTE_SELECTION_EVENT";
    static ROUTE_DETAILS_BACK_EVENT = "ROUTE_DETAILS_BACK_EVENT";
    static ASSET_HANDLE_CLICK_EVENT = "ASSET_HANDLE_CLICK_EVENT";
    static SELECT_DATE = null;
    static ROUTE_IN_PROGRESS = "In progress";
    static ROUTE_IN_COMPLETED = "Completed";



    //static RPC_ADMIN = "RpcAdmin";
    //static RPC_READ_ONLY = "RpcReadOnly";

    static CUSTOMER_ADMIN = "CustomerAdmin";
    static LOCATION_ADMIN = "LocationAdmin";
    static LIFT_MAINTENANCE_EVENT = "LIFT_MAINTENANCE_EVENT";
    static LIFT_ADD_MAINTENANCE_EVENT = "LIFT_ADD_MAINTENANCE_EVENT";
    static STOP_SCAN_PALLET = "StopScanPallet";

    static SUCCESS = "success";
    static ERROR = "error";



    static  barcodeData = "";
    static IS_BARCODE_SCANNER_START = false;
    static  barcodes = []

    static setScanBarcodes(barCode){
        Constants.barcodeData = barCode;
    }
    static clearBarcodes(){
        Constants.barcodeData = "";
        Constants.barcodes = []
    }
    static getBarcodes(){
        return Constants.barcodeData
    }




   // static locationId = ""
    //static customerId = "";

    static IS_PROGRESS_ENABLED = false;



}
